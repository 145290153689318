import React, { FC, ChangeEvent } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

const StyledRadio = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  appearance: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  opacity: 0;

  &.focus-visible {
    opacity: 1;
  }
`

const StyledLabel = styled.label.attrs(({ checked }: { checked: boolean }) => ({
  className: checked && 'checked',
}))<{ checked: boolean }>`
  position: relative;
  display: block;
  border-width: 1px;
  padding: 1px;
  border-style: solid;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  ${s({ borderColor: 'colorBorder02', mb: 6 })}
  &.checked {
    border-style: solid;
    border-width: 2px;
    padding: 0;
    ${s({ borderColor: 'colorInteractionButton' })}
  }
  &:hover {
    ${s({ boxShadow: 1 })}
  }
`

type SelectorProps = {
  label: string
  id: string
  checked: boolean
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const Selector: FC<React.PropsWithChildren<SelectorProps>> = ({
  label,
  id,
  checked,
  value,
  onChange,
  children,
}) => {
  return (
    <StyledLabel key={label} checked={checked}>
      <StyledRadio
        type="radio"
        checked={checked}
        value={value}
        id={id}
        data-testid={id}
        onChange={onChange}
        aria-label={label}
      />
      {children}
    </StyledLabel>
  )
}
