import React, { useState, FC, useEffect } from 'react'
import {
  CarouselItem,
  UnstyledCarouselScrollArea,
  CarouselRoot,
  CarouselState,
  Image,
  CarouselControls,
  CarouselPreviousButton,
  CarouselNextButton,
  Box,
  ScreenReaderOnly,
} from '@moonpig/launchpad-components'
import styled, { css } from 'styled-components'
import { system as s } from '@moonpig/launchpad-system'
import { ThemeInterface, colorValue, spacingPx } from '@moonpig/launchpad-theme'
import YouTube, { YouTubeProps } from 'react-youtube'
import { IconSystemPlayOutline } from '@moonpig/launchpad-assets'
import { breakpoint } from '@moonpig/launchpad-utils'
import { trackGAEvent, useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { useGiftImageCarouselLocaleText } from './GiftImageCarousel.locale'
import { FavouriteButton } from '../FavouriteButton'
import { ProductImage } from '../../types'
import { useProductsLocaleText } from '../../locale'

const StyledMainImageContainer = styled(Box)`
  ${s({
    position: 'relative',
    mb: { xs: 4, lg: 5 },
    borderRadius: 3,
    overflow: 'hidden',
    bgcolor: 'colorBackground01',
  })}
  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

const StyledMainBox = styled(Box)`
  ${s({ bgcolor: 'colorBackground01' })}
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  background: #fff;
  line-height: 0;
`

const StyledMainImage = styled(Image)`
  width: 100%;
  background: ${colorValue('colorBackground01')};
`
// video with id dHWbsbYVAIg has tiny black borders on sides, so we need to hide them until video is replaced
const StyledVideoWrapper = styled(Box)`
  position: relative;
  padding-bottom: 100%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: -1px;
    width: 101%;
    height: 101%;
  }
`

const StyledButton = styled.button<{ active: boolean; theme: ThemeInterface }>`
  ${s({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: { xs: '5rem', md: '6rem', lg: '7.35rem' },
    bgcolor: 'colorBackground01',
    borderRadius: 2,
    overflow: 'hidden',
  })}

  &::after {
    ${({ active, theme }) =>
      active &&
      css`
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        ${s({ borderRadius: 2 })}
        border: solid ${spacingPx(2)({ theme })} ${colorValue('colorPrimary02')(
          {
            theme,
          },
        )};

        ${breakpoint('lg')} {
          border: solid ${spacingPx(3)({ theme })}
            ${colorValue('colorPrimary02')({ theme })};
        }
      `}
  }
`

const StyledVideoPlayOverlay = styled(Box)`
  ${s({
    color: 'colorBlack00',
    position: 'absolute',
    borderRadius: 2,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mb: { xs: 0, lg: 0 },
  })}
  background: rgba(0, 0, 0, 0.3);
`

const StyledCarouselRoot = styled(CarouselRoot)`
  ${s({
    px: { xs: 5, md: 6 },
  })}
`

const StyledCarouselScrollArea = styled(UnstyledCarouselScrollArea)`
  ${({ theme }) =>
    s({
      gap: { xs: `${theme.spacing[4]}px`, lg: `${theme.spacing[5]}px` },
      py: 2,
    })}

  padding-top: 2px;
`

const StyledFavouriteButton = styled(FavouriteButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`

const StyledThumbnailImage = styled(Image)`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`
const StyledIconSystemPlayOutline = styled(IconSystemPlayOutline)`
  ${s({
    width: { xs: '40px', md: '50px' },
  })}
`

const Carousel: FC<React.PropsWithChildren<{ title: string }>> = ({
  title,
  children,
}) => {
  const localiseText = useGiftImageCarouselLocaleText()
  return (
    <Box>
      <CarouselState>
        <Box marginX={{ xs: -5, md: -6 }}>
          <StyledCarouselRoot
            title={localiseText('common.gift_image_carousel.root_title_text', {
              title,
            })}
          >
            <StyledCarouselScrollArea>{children}</StyledCarouselScrollArea>
            <CarouselControls>
              <CarouselPreviousButton />
              <CarouselNextButton />
            </CarouselControls>
          </StyledCarouselRoot>
        </Box>
      </CarouselState>
    </Box>
  )
}

type TrackingData = {
  label: string
  category: string
  action: string
}

const trackGASelectEvent = ({ category, action, label }: TrackingData) => {
  trackGAEvent({
    event: 'select_content',
    content_data: {
      content_type: `${category} | ${action} | ${label}`,
    },
    event_data: {
      category,
      action,
      label,
      non_interaction: true,
      value: undefined,
    },
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  })
}

const getCarouselPopulatedWithVideo = (
  images: GiftImageCarouselItem[],
  productVideoId?: string,
) => {
  if (!productVideoId) return images

  const videoItem = { ...images[0], videoId: productVideoId }

  return [images[0], videoItem, ...images.slice(1)]
}

const mapProductImagesToCarouselImages = (
  productImages: ProductImage[],
  productVideoId?: string,
): Array<GiftImageCarouselItem | GiftVideoCarouselItem> => {
  const images = productImages.map(image => ({
    small: image.small.jpgUrl,
    medium: image.medium.jpgUrl,
    large: image.large.jpgUrl,
    extraLarge: image.extraLarge.jpgUrl,
  }))

  return getCarouselPopulatedWithVideo(images, productVideoId)
}

const mapImagesToCarouselImages = (
  images: string[],
  productVideoId?: string,
): Array<GiftImageCarouselItem | GiftVideoCarouselItem> => {
  const carouselImages = images.map(src => ({
    small: src,
    medium: src,
    large: src,
    extraLarge: src,
  }))

  return getCarouselPopulatedWithVideo(carouselImages, productVideoId)
}

type ImageSizeKey = 'small' | 'medium' | 'large' | 'extraLarge'
type GiftImageCarouselItem = Record<ImageSizeKey, string>

type VideoKey = 'small' | 'medium' | 'large' | 'extraLarge' | 'videoId'
type GiftVideoCarouselItem = Record<VideoKey, string>

export const GiftImageCarousel: FC<{
  images: string[]
  title: string
  id?: string
  productImages?: ProductImage[]
  isFavourited: boolean
  onFavouriteToggle: () => void
  productVideoId?: string
}> = ({
  images,
  title,
  id,
  productImages,
  isFavourited,
  onFavouriteToggle,
  productVideoId,
}) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0)
  const localeText = useProductsLocaleText()
  const { trackGAEventOnce } = useTrackGAEventOnce()
  const carouselItems: Array<GiftImageCarouselItem | GiftVideoCarouselItem> =
    productImages
      ? mapProductImagesToCarouselImages(productImages, productVideoId)
      : mapImagesToCarouselImages(images, productVideoId)

  const handleSelectCarouselItem = (index: number) => {
    setSelectedItemIndex(index)

    if (productVideoId) {
      const selectedItem = carouselItems[index]
      const isVideo = 'videoId' in selectedItem

      const baseLabel = `${id} | item ${index + 1} of ${carouselItems.length}`
      const trackingData: TrackingData = {
        category: 'gift media carousel',
        label: isVideo
          ? `${baseLabel} | video id ${selectedItem.videoId}`
          : baseLabel,
        action: isVideo ? 'select video' : 'select image',
      }

      trackGASelectEvent(trackingData)
    }
  }

  const selectedItem = carouselItems[selectedItemIndex]
  const videoOptions: YouTubeProps['opts'] = {
    playerVars: {
      autoplay: 1,
      loop: 1,
      rel: 0,
      enablejsapi: 1,
      playlist: productVideoId,
    },
  }

  useEffect(() => {
    const impressionTrackingEvent = {
      event: 'impression',
      content_data: {
        content_type: `gift media carousel | view media carousel | video id ${productVideoId}`,
      },
      event_data: {
        category: 'gift media carousel',
        action: 'view media carousel',
        label: `${id} | video id ${productVideoId}`,
        non_interaction: true,
        value: undefined,
      },
      discount_data: undefined,
      ecommerce: undefined,
      error_data: undefined,
    }

    if (productVideoId) {
      trackGAEventOnce(impressionTrackingEvent)
    }
  }, [productVideoId, id, trackGAEventOnce])

  return (
    <Box
      data-testid="mp-find-gift-image-carousel"
      data-product-id={id}
      maxWidth="30.25rem"
      margin="0 auto"
    >
      {carouselItems.length && (
        <StyledMainImageContainer>
          <StyledMainBox>
            <StyledFavouriteButton
              title={title}
              isFavourited={isFavourited}
              onFavouriteToggle={onFavouriteToggle}
            />
            {selectedItem && 'videoId' in selectedItem ? (
              <StyledVideoWrapper>
                <YouTube
                  videoId={selectedItem.videoId}
                  title={`video-${selectedItem.videoId}`}
                  aria-label={localeText('product_video.video_label')}
                  opts={videoOptions}
                />
              </StyledVideoWrapper>
            ) : (
              <StyledMainImage
                src={selectedItem.large}
                zoomSrc={selectedItem.large}
                alt={title}
                width="100%"
              />
            )}
          </StyledMainBox>
        </StyledMainImageContainer>
      )}
      {carouselItems.length > 1 ? (
        <Carousel title={title}>
          {carouselItems.map((carouselItem, index) => (
            <CarouselItem
              key={carouselItem.small}
              onClick={() => handleSelectCarouselItem(index)}
              tabIndex={undefined}
            >
              <Box position="relative" height="100%">
                <StyledButton
                  className="focus-visible"
                  active={index === selectedItemIndex}
                >
                  {'videoId' in carouselItem ? (
                    <>
                      <ScreenReaderOnly>
                        {localeText('product_video.play_video_label', title)}
                      </ScreenReaderOnly>
                      <StyledThumbnailImage
                        data-testid={`youtube-thumbnail-${carouselItem.videoId}`}
                        src={carouselItem.small}
                        alt={localeText('product_video.video_thumbnail', title)}
                      />
                      <StyledVideoPlayOverlay>
                        <StyledIconSystemPlayOutline />
                      </StyledVideoPlayOverlay>
                    </>
                  ) : (
                    <Image width="100%" src={carouselItem.small} aria-hidden />
                  )}
                </StyledButton>
                <ScreenReaderOnly>
                  {localeText('product_image.image_label', title, index + 1)}
                </ScreenReaderOnly>
              </Box>
            </CarouselItem>
          ))}
        </Carousel>
      ) : null}
    </Box>
  )
}
