import {
  Box,
  Pill,
  Modal,
  Heading,
  IconButton,
  Text,
} from '@moonpig/launchpad-components'
import { IconInformation } from '@moonpig/launchpad-assets'
import React, { FC, createRef, useState } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useProductsLocaleText } from '../../locale'

const StyledButton = styled(IconButton)`
  ${s({ color: 'colorFeedbackInformation' })};
`

export const SponsoredPill: FC = () => {
  const t = useProductsLocaleText()
  const [open, setOpen] = useState(false)
  const modalTriggerRef = createRef<HTMLButtonElement>()

  return (
    <>
      <Pill
        aria-label={'sponsored product tile'}
        label={t('sponsored_pill.pill_label')}
        variant={'sponsored'}
        buttonIcon={
          <StyledButton
            onClick={() => setOpen(true)}
            color="colorFeedbackInformation"
            icon={IconInformation}
            label={t('sponsored_pill.pill_label')}
            aria-label={'sponsored product information'}
            ref={modalTriggerRef}
          />
        }
      />
      <Modal
        label={t('sponsored_pill.modal_label')}
        isOpen={open}
        onDismiss={() => setOpen(false)}
        hasCloseButton
        triggerRef={modalTriggerRef}
      >
        <Heading level="h2" mb={6} textAlign="left" tabIndex={0}>
          {t('sponsored_pill.modal_label')}
        </Heading>
        <Box textAlign="left" width="700px">
          <Text as={'p'} tabIndex={0}>
            {t('sponsored_pill.modal_content')}
          </Text>
        </Box>
      </Modal>
    </>
  )
}
