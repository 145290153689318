import { sanitizeStrings } from './helpers/sanitizeStrings'

type Basket = {
  id?: string
  size: number
  action: string
  promotionName?: string
  totalDiscount?: number
}

export type Product = {
  item_id: string
  item_name: string
  coupon?: string
  discount?: number
  item_brand: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_list_id?: string
  item_list_name?: string
  item_variant?: string
  design_id?: string
  price: number
  quantity: number
  item_cart_index?: number
  is_sponsored?: 'true' | 'false' | 'internal'
}

type ProductCartEventDetails = {
  basket: Basket
  products: Product[]
  action: string
  currency: string
  event: string
  totalValue: number
  label: string
  productIndex?: number
  listName?: string
  eventCategory?: string
}

export const createBasketEvent = ({
  event,
  basket,
  products,
  action,
  currency,
  totalValue,
  label,
  productIndex,
  eventCategory,
}: ProductCartEventDetails) => {
  let basketSize = basket.size
  if (event === 'add_to_cart') {
    basketSize = basket.size - products[0].quantity
  } else if (event === 'remove_from_cart') {
    basketSize = basket.size + products[0].quantity
  }
  return {
    event,
    basket_data: {
      id: basket.id,
      size: basketSize,
      update_initiator: sanitizeStrings(basket.action),
    },
    ecommerce: {
      currency: currency.toLocaleUpperCase(),
      value: totalValue,
      coupon: basket.promotionName,
      discount: basket.totalDiscount,
      items: products.map((product, index) => ({
        coupon: product.coupon,
        discount: product.discount,
        index: product.item_cart_index || productIndex || index + 1,
        item_brand: product.item_brand,
        item_category: sanitizeStrings(product.item_category),
        item_category2: sanitizeStrings(product.item_category2),
        item_category3: sanitizeStrings(product.item_category3),
        item_category4: sanitizeStrings(product.item_category4),
        item_category5: sanitizeStrings(product.item_category5),
        item_id: product.item_id.toLocaleLowerCase(),
        item_list_id: sanitizeStrings(product.item_list_id),
        item_list_name: sanitizeStrings(product.item_list_name),
        item_name: sanitizeStrings(product.item_name),
        item_variant: sanitizeStrings(product.item_variant),
        design_id: product.design_id,
        price: product.price,
        quantity: product.quantity,
        is_sponsored: product.is_sponsored || 'false',
      })),
    },
    event_data: {
      action: sanitizeStrings(action),
      category: eventCategory
        ? eventCategory.toLocaleLowerCase()
        : 'product action',
      label: label.toLocaleLowerCase(),
      non_interaction: true,
      value: Math.round(totalValue),
    },

    // Web only //
    content_data: undefined,
    discount_data: undefined,
    error_data: undefined,

    // For GTM //
    application_data: {
      datalayer_version: 1,
    },
  }
}
