import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import {
  Grid,
  Box,
  Flex,
  Text,
  PrimaryButton,
  SecondaryButton,
  Rating,
  Alert,
  Heading,
  Pill,
  InfoStrip,
} from '@moonpig/launchpad-components'
import {
  IconInformation,
  IllustrationTruckEnvelope,
} from '@moonpig/launchpad-assets'
import { styled, breakpointDown } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useInView } from 'react-intersection-observer'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import {
  useTrackGAEventOnce,
  trackGAEvent,
  sanitizeStrings,
} from '@moonpig/web-core-analytics'
import { useStoreId } from '@moonpig/web-core-stores'
import { useProductsLocaleText } from '../../locale'
import { StickyCta } from './StickyCta'
import { ProductQuickViewVariant } from './types'
import { VariantCategory } from './VariantCategorySelector'
import { MultiVariantSelector } from './MultiVariantSelector'
import { QuantitySelector } from '../QuantitySelector'
import { AddonsFieldsFragment } from '../../queries/useProductWithAddonsQuery'
import {
  ButtonActionName,
  ProductInfoTrackingData,
  ProductPill,
  UpsellProduct,
  RequiresEditing,
} from '../../types'
import { CardImageCarousel } from '../ProductInfo/CardImageCarousel'
import { GiftImageCarousel } from '../GiftImageCarousel'
import {
  createPersonaliseGAEvent,
  createProductCTAImpressionGAEvent,
  createViewItemsWithAddonsGAEvent,
} from '../../analytics'
import { transformPillVariant } from '../../utils/transformPill'
import { ProductUpsell } from '../ProductUpsell'
import { ProductPills } from '../ProductPills'
import { ProductPrice } from '../ProductPrice'
import { SoldOutBanner } from '../SoldOutBanner'
import { MultiAddOnSelector } from '../MultiAddOnSelector'

const E_CARD_SIZE_ID = '135'

const DEFAULT_QUANTITY = 1
const MAX_CARD_QUANTITY = 99
const MAX_GIFT_QUANTITY = 10
const TRUCK_ENVELOPE_ICON_SIZE = '48'

const CTA_CONTAINER_MIN_HEIGHT = {
  desktop: '52px',
  mobile: '85px',
}

const StyledIcon = styled(IllustrationTruckEnvelope)`
  ${s({
    mr: 4,
    minWidth: `${TRUCK_ENVELOPE_ICON_SIZE}px`,
  })}
`

const StyledDetailsWrapper = styled(Box)`
  text-align: left;

  ${breakpointDown('md')} {
    padding-bottom: 0;
  }
`

const StyledSoldOutOverlay = styled(Box)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.75;
  ${s({
    typography: { xs: 'typeMobileDisplay01', md: 'typeDesktopDisplay02' },
    bgcolor: 'palette.white',
  })}
`

const StyledCtaContainer = styled.div`
  width: 100%;
  min-height: ${CTA_CONTAINER_MIN_HEIGHT.desktop};
  ${s({
    borderTop: { xs: 1, md: 0 },
    borderColor: { xs: 'palette.eclipse.c140', md: 'none' },
    boxShadow: { xs: 4, md: 0 },
    mb: { xs: 0, md: 6 },
  })}
  ${breakpointDown('md')} {
    bottom: 0;
    left: 0;
    position: fixed;
    background-color: white;
    z-index: 3;
    min-height: ${CTA_CONTAINER_MIN_HEIGHT.mobile};
    ${s({
      p: 6,
    })}
    justify-content: center;
  }
`

const StyledCtaContainerAnimated = styled.div`
  display: flex;
  align-items: center;
  animation: fadeIn 200ms forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const StyledMoreInfoLinkBox = styled(Box)`
  display: flex;
  width: 100%;

  ${s({
    justifyContent: 'center',
    my: { xs: 8, md: 0 },
  })}
`

const isAddonExists = (
  addons?: AddonsFieldsFragment[] | null,
  addon?: AddonsFieldsFragment | null,
) => {
  return addon ? !!addons?.some(({ sku }) => addon.sku === sku) : false
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StyledCompAs = keyof JSX.IntrinsicElements | React.ComponentType<any>

type ProductQuickViewProps = {
  id: string
  title: string
  titleAs?: StyledCompAs
  carouselItems: JSX.Element[]
  productImages: string[]
  variants: ProductQuickViewVariant[]
  variantsTitle?: string
  variantCategories?: VariantCategory[]
  variantCategoriesTitle?: string
  trackingData: ProductInfoTrackingData
  onVariantChange?: (variantValue: string) => void
  rating?: {
    score: number
    count?: number
  }
  moreInfoLinkElement?: JSX.Element
  infoAlertTitle?: string
  infoAlertText?: string
  variantInfoLinkText?: string
  variantInfoLink?: string
  onAddToBasket: (
    selectedAddon: AddonsFieldsFragment | null,
    buttonActionName?: ButtonActionName,
    requiresEditingValue?: boolean,
  ) => void
  dispatchTimeMessage?: JSX.Element
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  leftWidth?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rightWidth?: any
  'data-testid'?: string
  addToBasketText?: string
  quantity?: number
  onQuantityChange?: (newQuantity: number) => void
  enableStickyCta?: boolean
  imageDimensions?: {
    width: number
    height: number
  }
  hideStickyCta?: boolean
  isLandscape: boolean
  isCardProduct: boolean
  requiresEditing?: RequiresEditing
  groupCardProject?: string
  isProductCustomisable?: boolean
  primaryProductPill?: ProductPill | null
  productPills?: ProductPill[]
  isFromModal?: boolean
  disableQuantitySelector?: boolean
  upsellProduct?: UpsellProduct
  onUpsellProductClick?: (event: React.MouseEvent) => void
  isFavourited: boolean
  onFavouriteToggle: () => void
  isSponsored?: boolean
  internallyPromoted?: boolean
}

export const ProductQuickView: FC<ProductQuickViewProps> = ({
  id,
  titleAs = 'p',
  title,
  carouselItems,
  productImages,
  variants,
  variantsTitle,
  variantCategories,
  variantCategoriesTitle,
  variantInfoLinkText,
  variantInfoLink,
  onVariantChange,
  rating,
  moreInfoLinkElement,
  infoAlertTitle,
  infoAlertText,
  onAddToBasket,
  dispatchTimeMessage,
  leftWidth = { xs: 1, md: 3 / 5, lg: 1 / 2 },
  rightWidth = { xs: 1, md: 2 / 5, lg: 1 / 2 },
  'data-testid': testId,
  addToBasketText,
  quantity = DEFAULT_QUANTITY,
  onQuantityChange,
  enableStickyCta,
  imageDimensions,
  trackingData,
  isLandscape,
  hideStickyCta = false,
  isCardProduct,
  requiresEditing,
  groupCardProject,
  isProductCustomisable,
  primaryProductPill,
  productPills,
  isFromModal,
  disableQuantitySelector = false,
  upsellProduct,
  onUpsellProductClick,
  isFavourited,
  onFavouriteToggle,
  isSponsored,
  internallyPromoted,
}) => {
  const storeId = useStoreId()
  const { trackGAEventOnce } = useTrackGAEventOnce()
  const localiseText = useCoreLocaleText()
  const localiseProductsText = useProductsLocaleText()
  const localisedAddToBasketText =
    addToBasketText || localiseText('common.add_to_basket')

  const [ctaButton, ctaButtonInView] = useInView({
    threshold: 0,
    rootMargin: '-200px 0px 1000px 0px',
    initialInView: true,
  })

  const inStock =
    variants && variants.filter(variant => variant.inStock).length > 0

  const [selectedAddon, setSelectedAddon] =
    useState<AddonsFieldsFragment | null>(null)

  const viewItemEventWasTracked = useRef(false)
  const selectedVariant = useMemo(
    () => variants.find(({ selected }) => selected),
    [variants],
  )

  const selectedVariantInStockAddons = useMemo(() => {
    const selectedVariantAddons = selectedVariant?.addons?.length
      ? selectedVariant.addons
      : null
    return selectedVariantAddons
      ? selectedVariantAddons.filter(addon => addon.inStock)
      : null
  }, [selectedVariant])

  useEffect(() => {
    const isNewSelectedVariantContainsSelectedAddon = isAddonExists(
      selectedVariantInStockAddons,
      selectedAddon,
    )
    if (!isNewSelectedVariantContainsSelectedAddon) {
      setSelectedAddon(null)
    }
  }, [selectedVariantInStockAddons, selectedAddon])

  useEffect(() => {
    const {
      pageLocation,
      product: {
        categoryName,
        price,
        title: productTitle,
        variantTitle,
        categoryDepartment,
        index,
        addonLoaded = true,
        inStock: productInStock,
      },
    } = trackingData

    const product = {
      id,
      category:
        sanitizeStrings(categoryDepartment) ||
        /* istanbul ignore next */ categoryName,
      title: productTitle,
      variantTitle,
      price,
      index,
      categoryDepartment,
      inStock: productInStock,
      isLandscape,
      isSponsored,
      internallyPromoted,
    }

    const shouldTrackEvent =
      (moreInfoLinkElement || pageLocation !== 'product details') && addonLoaded
    const shouldTrackEventOnPDP =
      !moreInfoLinkElement && pageLocation === 'product details'

    if (shouldTrackEvent || shouldTrackEventOnPDP) {
      if (!viewItemEventWasTracked.current) {
        trackGAEvent(
          createViewItemsWithAddonsGAEvent({
            product,
            variants,
            pageLocation,
          }),
        )
        viewItemEventWasTracked.current = true
      }
    }
  }, [
    moreInfoLinkElement,
    trackingData,
    id,
    title,
    variants,
    storeId,
    isLandscape,
    isSponsored,
    internallyPromoted,
  ])

  const onAddToBasketClick = () => {
    onAddToBasket(
      selectedAddon,
      ButtonActionName.ADD_TO_BASKET,
      requiresEditing?.value,
    )
  }

  const onPersonaliseClick = () => {
    if (isProductCustomisable) {
      const productData = {
        id,
        name: trackingData.product.title,
        price:
          selectedVariant?.price.centAmount ||
          /* istanbul ignore next */ trackingData.product.price,
        variant:
          selectedVariant?.label ||
          /* istanbul ignore next */ trackingData.product.variantTitle,
        quantity,
        category: trackingData.product.categoryName,
      }
      const componentName = isFromModal ? 'popup' : 'product-details'
      trackGAEvent(
        createPersonaliseGAEvent({
          componentName,
          productData,
        }),
      )
    }

    onAddToBasket(
      selectedAddon,
      ButtonActionName.PERSONALISE,
      requiresEditing?.value,
    )
  }

  const shouldShowCardQuantitySelector = isCardProduct && !groupCardProject

  const shouldShowGiftQuantitySelector =
    !isCardProduct && !isProductCustomisable

  const shouldShowQuantitySelector =
    (shouldShowCardQuantitySelector || shouldShowGiftQuantitySelector) &&
    !disableQuantitySelector

  const isEcardVariantSelected = selectedVariant?.value === E_CARD_SIZE_ID

  const skipEditorEnabled =
    isCardProduct && !groupCardProject && !isEcardVariantSelected

  if (!requiresEditing?.loading && skipEditorEnabled) {
    trackGAEventOnce(
      createProductCTAImpressionGAEvent(requiresEditing?.value, id),
    )
  }

  const quantitySelectorComponent = ({
    fullWidth = false,
  }: {
    fullWidth?: boolean
  } = {}) =>
    shouldShowQuantitySelector ? (
      <QuantitySelector
        id={`${id}-quantity-select`}
        quantity={quantity}
        onQuantityChange={onQuantityChange}
        label={
          fullWidth
            ? localiseText('common.select_quantity')
            : localiseText('common.quantity_abbreviation')
        }
        width={fullWidth ? 1 : { xs: 3 / 7, md: 1 / 4 }}
        disabled={isEcardVariantSelected}
        fullWidth={fullWidth}
        maxQuantity={isCardProduct ? MAX_CARD_QUANTITY : MAX_GIFT_QUANTITY}
      />
    ) : null

  const variant = variants[0]

  return (
    <Grid data-testid={testId} gap>
      {enableStickyCta && !hideStickyCta && !isFromModal && (
        <StickyCta
          title={title}
          addToBasketText={localisedAddToBasketText}
          quantitySelectorElement={quantitySelectorComponent() || undefined}
          leftWidth={leftWidth}
          rightWidth={rightWidth}
          region={storeId}
          ctaButtonInView={ctaButtonInView}
          skipEditorEnabled={
            skipEditorEnabled && requiresEditing?.value === false
          }
          onAddToBasketClick={onAddToBasketClick}
          onPersonaliseClick={onPersonaliseClick}
        />
      )}
      <Box width={leftWidth} mt={7} data-testid="section-images">
        <Box mb={6} position="relative">
          {!isFromModal && isCardProduct && !!primaryProductPill && (
            <Flex pt={4} pb={4} alignSelf="center" justifyContent={'center'}>
              <Pill
                label={primaryProductPill.displayLabel}
                variant={transformPillVariant(
                  primaryProductPill.displayVariant,
                )}
              />
            </Flex>
          )}
          {!inStock && (
            <StyledSoldOutOverlay>
              {localiseText('common.sold_out')}
            </StyledSoldOutOverlay>
          )}
          {!isCardProduct && productImages.length ? (
            <GiftImageCarousel
              images={productImages}
              title={title}
              isFavourited={isFavourited}
              onFavouriteToggle={onFavouriteToggle}
            />
          ) : (
            <CardImageCarousel
              id={id}
              title={title}
              carouselItems={carouselItems}
              imageDimensions={imageDimensions}
              isFavourited={isFavourited}
              onFavouriteToggle={onFavouriteToggle}
            />
          )}
        </Box>
      </Box>

      <>
        <StyledDetailsWrapper width={rightWidth}>
          {title && (
            <Text
              typography={{
                xs: 'typeMobileDisplay01',
                md: 'typeDesktopDisplay02',
              }}
              as={titleAs}
              mb={6}
              data-testid="Boo"
            >
              {title}
            </Text>
          )}
          {!isCardProduct &&
            Boolean(primaryProductPill || productPills?.length) && (
              <Box py={6}>
                <ProductPills
                  primaryProductPill={primaryProductPill}
                  productPills={productPills}
                />
              </Box>
            )}
          {rating && (
            <Box mb={6}>
              <Rating
                rating={rating.score}
                totalRatingsText={
                  rating.count
                    ? `(${localiseProductsText(
                        'rating.rating_count',
                        rating.count,
                      )})`
                    : undefined
                }
                totalRatingsScreenReaderText={
                  rating.count
                    ? localiseProductsText('rating.total_reviews')
                    : undefined
                }
                starSize={24}
              />
            </Box>
          )}
          <Flex display={{ md: 'none' }} justifyContent="space-between">
            <Box width={1} mb={5} display={{ sm: 'block', md: 'none' }}>
              {quantitySelectorComponent({ fullWidth: true })}
            </Box>
          </Flex>

          {inStock ? (
            <>
              {variantCategories || variants.length > 1 ? (
                <MultiVariantSelector
                  selectedVariant={selectedVariant}
                  variants={variants}
                  variantCategories={variantCategories}
                  variantCategoriesTitle={variantCategoriesTitle}
                  variantsTitle={variantsTitle}
                  onVariantChange={
                    onVariantChange ?? /* istanbul ignore next */ (() => {})
                  }
                  variantInfoLink={variantInfoLink}
                  variantInfoLinkText={variantInfoLinkText}
                  id={id}
                  isLandscape={isLandscape}
                  isProductPage={!moreInfoLinkElement}
                />
              ) : (
                <div>
                  <Box
                    display={{ xs: 'block', md: 'inline-block' }}
                    mr={6}
                    mb={variant.description ? 2 : /* istanbul ignore next */ 6}
                  >
                    <ProductPrice
                      priceCentAmount={variant.price.centAmount}
                      fullPriceCentAmount={variant.fullPrice?.centAmount}
                      currencyCode={variant.price.currencyCode}
                      fractionDigits={variant.price.fractionDigits}
                      minimumQuantity={variant.minimumQuantity}
                      discountedPercentage={variant.discountedPercentage}
                      description={variant.price.description}
                    />
                  </Box>
                  {variant.description ? (
                    <Text
                      as="p"
                      textAlign="left"
                      mb={6}
                      typography="typeBodyCaption"
                      lineHeight={1}
                    >
                      {variant.description}
                    </Text>
                  ) : null}
                  {upsellProduct && upsellProduct.masterVariant.inStock && (
                    <ProductUpsell
                      upsellProduct={upsellProduct}
                      selectedVariantPrice={variant.price}
                      trackingData={trackingData}
                      onClick={onUpsellProductClick}
                    />
                  )}
                </div>
              )}
              {selectedVariantInStockAddons &&
              selectedVariantInStockAddons.length ? (
                <Box
                  bgcolor="colorBackground06"
                  py={8}
                  px={{ xs: 5, lg: 8 }}
                  mx={{ xs: -5, md: 0 }}
                  mb={6}
                >
                  <MultiAddOnSelector
                    addons={selectedVariantInStockAddons}
                    selectedAddon={selectedAddon}
                    onAddClick={addon => setSelectedAddon(addon)}
                    onRemoveClick={() => setSelectedAddon(null)}
                  />
                </Box>
              ) : null}
              {dispatchTimeMessage && (
                <Box mb={6}>
                  <InfoStrip fullWidth leftAlignContent>
                    <StyledIcon
                      width={TRUCK_ENVELOPE_ICON_SIZE}
                      height={TRUCK_ENVELOPE_ICON_SIZE}
                    />
                    {dispatchTimeMessage}
                  </InfoStrip>
                </Box>
              )}
              <Grid flex alignItems="center">
                {infoAlertText && (
                  <Box textAlign="left" order={1} width={{ xs: 1 }} mb={6}>
                    <Alert variant="info" icon={IconInformation} py={6}>
                      <Heading
                        level="h4"
                        mb={0}
                        typography={{
                          xs: 'typeMobileDisplay05',
                          md: 'typeDesktopDisplay06',
                        }}
                      >
                        {infoAlertTitle}
                      </Heading>
                      <Text mb={0}>{infoAlertText}</Text>
                    </Alert>
                  </Box>
                )}
                <Box width={1} m="auto">
                  <Box mb={6} display={{ xs: 'none', md: 'block' }}>
                    {quantitySelectorComponent({ fullWidth: true })}
                  </Box>
                  <StyledCtaContainer
                    ref={ctaButton}
                    data-testid="lp-find-product-quick-view-cta"
                  >
                    {skipEditorEnabled ? (
                      <>
                        {
                          /* istanbul ignore next */ !requiresEditing?.loading && (
                            <StyledCtaContainerAnimated>
                              {requiresEditing?.value === false && (
                                <SecondaryButton
                                  width={1 / 2}
                                  onClick={() => onAddToBasketClick()}
                                >
                                  {localiseText('common.add_to_basket')}
                                </SecondaryButton>
                              )}
                              <PrimaryButton
                                width={
                                  requiresEditing?.value === false ? 1 / 2 : 1
                                }
                                ml={requiresEditing?.value === false ? 6 : 0}
                                onClick={() => onPersonaliseClick()}
                              >
                                {localisedAddToBasketText}
                              </PrimaryButton>
                            </StyledCtaContainerAnimated>
                          )
                        }
                      </>
                    ) : (
                      <PrimaryButton
                        width={1}
                        onClick={() => onPersonaliseClick()}
                      >
                        {localisedAddToBasketText}
                      </PrimaryButton>
                    )}
                  </StyledCtaContainer>
                </Box>
                <StyledMoreInfoLinkBox>
                  {moreInfoLinkElement}
                </StyledMoreInfoLinkBox>
              </Grid>
            </>
          ) : (
            <Box py={6}>
              <SoldOutBanner />
            </Box>
          )}
        </StyledDetailsWrapper>
      </>
    </Grid>
  )
}
