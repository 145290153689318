import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

export const TABS_TEXT = {
  'tabs.single_card': {
    'en-GB': 'Single Card',
    'nl-NL': 'Eén kaart',
  },
  'tabs.single_card_label': {
    'en-GB': 'Show single card options',
    'nl-NL': 'Opties voor enkele kaarten weergeven',
  },
  'tabs.multi_card': {
    'en-GB': 'Multi-Buy',
    'nl-NL': 'Meerdere kaarten',
  },
  'tabs.multi_card_label': {
    'en-GB': 'Show multi-buy options',
    'nl-NL': 'Opties voor meerdere kaarten weergeven',
  },
  'tabs.group_card': {
    'en-GB': 'Group Card',
    'nl-NL': 'Groepskaart',
  },
  'tabs.group_card_label': {
    'en-GB': 'Show group card options',
    'nl-NL': 'Toon opties voor groepswenskaart',
  },
  'tabs.card': {
    'en-GB': 'Card',
    'nl-NL': 'Kaart',
  },
  'tabs.card_label': {
    'en-GB': 'Show card options',
    'nl-NL': '',
  },
  'tabs.group_card_list_title': {
    'en-GB': 'Your friends can write inside too!',
    'nl-NL': '',
  },
  'tabs.group_card_list_item_1': {
    'en-GB': "Make this a <b>'Group Card'</b>",
    'nl-NL': '',
  },
  'tabs.group_card_list_item_2': {
    'en-GB':
      '<b>Set a deadline, then share a link</b> for others to sign online',
    'nl-NL': '',
  },
  'tabs.group_card_list_item_3': {
    'en-GB': '<b>No account required</b> for them to sign',
    'nl-NL': '',
  },
  'tabs.group_card_list_item_4': {
    'en-GB': 'Collect up to <b>50 messages</b>',
    'nl-NL': '',
  },
}

export const SPONSORED_PILL_TEXT = {
  'sponsored_pill.pill_label': {
    'en-GB': 'Sponsored',
    'nl-NL': 'Samenwerking',
  },
  'sponsored_pill.modal_label': {
    'en-GB': 'Sponsored Content',
    'nl-NL': 'Gesponsorde content',
  },
  'sponsored_pill.modal_content': {
    'en-GB':
      'We collaborate with brand partners to show you products that could be relevant and interesting to you.',
    'nl-NL':
      'We werken samen met merkpartners om je producten te tonen die relevant en interessant voor je kunnen zijn.',
  },
}

export const MULTI_CARD_PROMOTION = {
  'multicard.how_to_send_to_different_addresses': {
    'en-GB': 'How to send to different addresses',
    'nl-NL': 'Zo verstuur je iets naar verschillende adressen',
  },
  'multicard.download_csv_template': {
    'en-GB': 'Download our CSV template',
    'nl-NL': 'Download het CSV-template',
  },
  'multicard.multi_buy_discounts_apply_to_all_cards_in_your_basket': {
    'en-GB': 'Multi-Buy discounts apply to all cards in your basket',
    'nl-NL': 'De stapelkorting geldt voor alle kaarten in je winkelmandje',
  },
  'multicard.discount': {
    'en-GB': (c: { discount: number }) => `${c.discount}% off`,
    'nl-NL': (c: { discount: number }) => `${c.discount}% korting`,
  },
  'multicard.regular_price_and_discounted_price': {
    'en-GB': (c: {
      regularPrice: string
      discountedPrice: string
      discount: number
      code: string
    }) =>
      `Regular price is ${c.regularPrice} and discounted price is ${c.discountedPrice} (${c.discount}% off with code ${c.code}})`,
    'nl-NL': (c: {
      regularPrice: string
      discountedPrice: string
      discount: number
    }) =>
      `De prijs is normaal ${c.regularPrice} en met korting is deze ${c.discountedPrice} (${c.discount}% korting)`,
  },
}

export const MESSAGING = {
  'messaging.sold_out': {
    'en-GB': 'This item is either sold out or no longer available.',
    'nl-NL': 'Dit artikel is uitverkocht of niet langer beschikbaar',
  },
  'messaging.its_not_you': {
    'en-GB': 'It’s not you, it’s us. Please try again!',
    'nl-NL': 'Het ligt niet aan jou, het ligt aan ons. Probeer het nog eens!',
  },
}

export const RATING = {
  'rating.rating_count': {
    'en-GB': (count: number) => `${Number(count).toLocaleString('en-GB')}`,
    'nl-NL': (count: number) => `${Number(count).toLocaleString('nl-NL')}`,
  },
  'rating.total_reviews': {
    'en-GB': 'Total reviews',
    'nl-NL': 'Totaal aantal beoordelingen',
  },
}

export const PRODUCT_VIDEO = {
  'product_video.video_label': {
    'en-GB': 'Video',
  },
  'product_video.play_video_label': {
    'en-GB': (productTitle: string) =>
      `Play video for ${productTitle} product information`,
  },
  'product_video.video_thumbnail': {
    'en-GB': (productTitle: string) =>
      `Video thumbnail for ${productTitle} product information`,
  },
}

export const PRODUCT_IMAGE = {
  'product_image.image_label': {
    'en-GB': (productTitle: string, index: number) =>
      `${productTitle} image ${index}`,
    'nl-NL': (productTitle: string, index: number) =>
      `${productTitle} afbeelding ${index}`,
  },
}

export const FAVOURITES = {
  'favourites.item_added_to_your_favourites': {
    'en-GB': 'Item added to your Favourites',
    'nl-NL': 'Artikel toegevoegd aan favorieten',
  },
  'favourites.view': {
    'en-GB': 'View',
    'nl-NL': 'Bekijk',
  },
}

export type ProductsDictionary =
  | keyof typeof TABS_TEXT
  | keyof typeof MULTI_CARD_PROMOTION
  | keyof typeof MESSAGING

export const useProductsLocaleText = createLocaleTextHook({
  dictionary: {
    ...TABS_TEXT,
    ...MULTI_CARD_PROMOTION,
    ...MESSAGING,
    ...SPONSORED_PILL_TEXT,
    ...RATING,
    ...PRODUCT_VIDEO,
    ...PRODUCT_IMAGE,
    ...FAVOURITES,
  },
})
