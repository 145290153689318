import React, { FC } from 'react'
import { Flex, Text, Box } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Selector } from './Selector'
import { ProductQuickViewVariant } from './types'
import { FormattedPrice } from '../../types'

const StyledFieldset = styled.fieldset`
  padding: 0;
  margin: 0;
  border-width: 0px;
  text-align: left;
`

const StyledLegend = styled.legend`
  ${s({ pb: 4, m: 0, typography: 'typeBodyLabel' })}
`

const StyledLink = styled.a`
  ${s({ paddingLeft: 4 })}
`

type VariantInfoProps = {
  label: string
  price?: FormattedPrice
  sku?: string
}

const VariantInfo: FC<VariantInfoProps> = ({ label, price }) => {
  return (
    <Flex flexDirection="column" justifyContent="center" flex="1" px={5} py={5}>
      <Text textAlign="center" as="p" mb={0} typography="typeBodyLabel">
        {label}
      </Text>
      {price && (
        <Text typography="typeDisplay05" textAlign="center">
          {price.currencySymbol}
          <span>{price.displayPrice}</span>
        </Text>
      )}
    </Flex>
  )
}

export type VariantCategory = {
  label: string
  variants: ProductQuickViewVariant[]
  price: FormattedPrice
}

type VariantCategorySelectorProps = {
  categoriesTitle?: string
  variantsTitle?: string
  variantCategories: VariantCategory[]
  variantInfoLinkText?: string
  variantInfoLink?: string
  onChange: (variantValue: string) => void
}

export const VariantCategorySelector: FC<VariantCategorySelectorProps> = ({
  categoriesTitle,
  variantsTitle,
  variantCategories,
  variantInfoLinkText,
  variantInfoLink,
  onChange,
}) => {
  const defaultCategory =
    variantCategories.find(category =>
      category.variants.find(variant => variant.selected),
    ) || variantCategories[0]

  const defaultCategoryVariant =
    defaultCategory.variants.find(variant => variant.selected) ||
    defaultCategory.variants[0]

  const [selectedCategory, setSelectedCategory] =
    React.useState(defaultCategory)

  const [selectedVariant, setSelectedVariant] = React.useState(
    defaultCategoryVariant.value,
  )

  return (
    <StyledFieldset>
      <StyledLegend>{categoriesTitle}</StyledLegend>
      {variantCategories.map(({ label, price, variants }, key) => {
        const id = `product-variant-category-${key}`
        const checked = selectedCategory.label === label
        const defaultVariantValue = variants[0].value
        return (
          <Box
            key={label}
            width={1 / variantCategories.length}
            display="inline-block"
            mb={4}
          >
            <Selector
              label={label}
              id={id}
              checked={checked}
              value={label}
              onChange={e => {
                setSelectedCategory(
                  variantCategories.find(
                    category => category.label === e.target.value,
                  ) || variantCategories[0],
                )
                setSelectedVariant(defaultVariantValue)
                onChange(defaultVariantValue)
              }}
            >
              <VariantInfo label={label} price={price} sku={variants[0].sku} />
            </Selector>
          </Box>
        )
      })}
      <StyledLegend>
        {variantsTitle}
        <StyledLink href={variantInfoLink} target="_blank" rel="noopener">
          {variantInfoLinkText}
        </StyledLink>
      </StyledLegend>

      {selectedCategory &&
        selectedCategory.variants.map(({ label, value }, key) => {
          const id = `product-variant-${key}`
          const checked = selectedVariant === value

          return (
            <Box
              key={label}
              width={{ lg: 1 / selectedCategory.variants.length }}
              display="inline-block"
            >
              <Selector
                label={label}
                key={label}
                id={id}
                checked={checked}
                value={value}
                onChange={e => {
                  const variantValue = e.target.value
                  setSelectedVariant(variantValue)
                  onChange(variantValue)
                }}
              >
                <VariantInfo label={label} />
              </Selector>
            </Box>
          )
        })}
    </StyledFieldset>
  )
}
